<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  import { Toast } from 'vant';
  export default {
    data() {
      return {

      }
    },
    methods: {
      onClickLeft() {
        Toast('返回');
      },
      onClickRight() {
        Toast('按钮');
      }
    }
  }
</script>
<style scoped lang="scss">
  .navbar {
    position: fixed;

  }
</style>
