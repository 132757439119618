import Vue from 'vue'
import Router from 'vue-router'
import child from "./modules/router"
import store from "../store/index"
import { Toast } from 'vant';

//处理跳转异常报错
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  routes: [{
    path: '/',
    component: () => import("../views/index/index"),
    children: [
      ...child
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("../views/login/index")
  },
  {
    path: '/register',
    name: 'register',//注册
    component: () => import("../views/register/index")
  },
  {
    path: '/goodDetail',
    name: 'Product details',//商品详情
    component: () => import("../views/goodDetail/index")
  },
  {
    path: '/order',
    name: 'Order management',//订单列表
    component: () => import("../views/order/index")
  },
  {
    path: '/orderDetail',
    name: 'Order details',//订单详情
    component: () => import("../views/order_detail/index")
  },
  {
    path: '/personalDetail',
    name: 'Personal information',//用户详情
    component: () => import("../views/personal_detail/index")
  },
  {
    path: '/qrcode',
    name: 'Invitation link',//我的二维码
    component: () => import("../views/qrcode/index")
  },
  {
    path: '/email',
    name: 'email',//输入邮箱
    component: () => import("../views/email/index")
  },
  {
    path: '/emailChecked',
    name: 'Check email',//检查邮箱
    component: () => import("../views/email_check/index")
  },
  {
    path: '/changPassword',
    name: 'Chang Password',//修改密码
    component: () => import("../views/changPwd/index")
  },
  {
    path: '/fansCurrent',
    name: 'Subordinates',//粉丝列表
    component: () => import("../views/fansCurrent/index")
  },
  {
    path: '/payment',
    name: 'Payment order',//订单支付
    component: () => import("../views/payment/index")
  },
  {
    path: '/userAgreement',
    name: 'Uuser Agreement',//用户协议
    component: () => import("../views/userAgreement/index")
  },
  {
    path: '/about',
    name: 'About Us',//关于我们
    component: () => import("../views/about/index")
  },
  {
    path: '/error',
    name: 'error',
    component: () => import("../views/error/index")
  },
  {
    path: '*',
    redirect: '/error',
  },
  ]
})

// 登录拦截
router.beforeEach((to, from, next) => {
  next()
  // if (to.path == "/login"||to.path =='/register') {
  //   next()
  //   return
  // }
  // // // 2、如果去的是其他页面，就验证token是否存在
  // if (store.state.user.token) {
  //   next()
  // } else {
  //   Toast('请先登录！')
  //   next("/login")
  // }
})


export default router
